import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthInfo } from '@core/models/interfaces/auth';
import { ResponseApi } from '@core/models/interfaces/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = `${environment.API_URL}/crm/auth`;

  constructor(private httpClient: HttpClient) {}

  getLocalToken() {
    return localStorage.getItem('accessToken');
  }

  getLocalUser() {
    return localStorage.getItem('user');
  }

  login(email: string, password: string) {
    return this.httpClient.post<ResponseApi<AuthInfo>>(`${this.baseUrl}/login`, {
      email,
      password
    });
  }

  forgetPassword(email?: string) {
    let params = new HttpParams();
    if (email) {
      params = params.set('email', email);
    }
    return this.httpClient.put(`${this.baseUrl}/forget-password`, null, { params });
  }
}
